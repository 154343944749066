import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import { routes } from 'inkp-routes/public';

import CantFindProduct from '../../components/CantFindProduct';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

export default class PrivacyPolicy extends React.Component<RouteComponentProps> {
  renderContent() {
    return (
      <div className="w-full px-1 privacy-policy ta-left">
        <style jsx={true}>{`
          h2 {
            font-size: 24px;
            font-weight: 700;
          }

          h3 {
            font-size: 20px;
            font-weight: bold;
          }

          h4 {
            font-size: 18px;
            font-weight: bold;
          }

          h5 {
            font-size: 16px;
            font-weight: bold;
          }

          .fs-18 {
            font-size: 18px;
          }
        `}</style>
        {/* Introduction */}
        <h1 className="mt-2 lg:mt-4 fs-xl fw-extra-bold">Introduction</h1>
        <p className="my-1 fs-18">
          We take seriously the privacy of our website Members and Visitors. Please read this privacy policy to learn
          more about how we treat information including personally identifiable information, in connection with your use
          of the inkpop.com website and online service (the “Service”). We provide this privacy policy because you have
          a right to know what information we collect, how it is used and protected, and the circumstances under which
          it may be shared or disclosed. If you have comments, suggestions, questions, or concerns regarding our privacy
          policy, please contact us at privacy@inkpop.com.
        </p>

        <h3 className="mt-1p5">What This Policy Covers</h3>
        <p className="my-p75">
          This policy covers the ways we treat information, including personally identifiable information, that we
          obtain from you when you visit our website, order Products through the Service, or register to become a Member
          of the Service. We regard “personally identifiable information” as information we obtain from you that someone
          can use to identify or contact you in person, such as your real name, telephone number, email address, or
          physical mailing address. Information that we use internally to identify you as a Member or a Visitor in our
          system (e.g., your User ID) is not considered personally identifiable information.
        </p>

        <h3 className="mt-1p5">What This Policy Does Not Cover</h3>
        <p className="my-p75">
          This policy does not cover the privacy practices of third parties, such as the practices of third-party
          payment processing or delivery services, partners, and/or advertisers. In addition, we may provide links to
          other services and on occasion to other websites that may interest you. All of these websites operate
          independently of the Service and each has its own privacy and security practices. We encourage you to review
          their policies before submitting any personal information to them.
        </p>

        <h3 className="mt-1p5">Consent To Collection & Processing In The United States</h3>
        <p className="my-p75">
          By using the Service, you consent to having your information, including personally identifiable information,
          transferred to and processed in the United States.
        </p>

        {/* How we collect information */}
        <h2 className="mt-3">How We Collect Information</h2>
        <h3 className="mt-1">Information You Provide</h3>
        <h4 className="mt-1">Information You Provide During Member Account Creation</h4>
        <p className="my-p75">
          When you create a registered Member account through the Service, we require that you provide your name, phone
          number, email address, and your website URL (if applicable).
        </p>

        <h4 className="mt-1">Information You Provide When Purchasing Products</h4>
        <p className="my-p75">
          When you purchase a Product through the Service, we require that you provide your name, phone number, email
          address, shipping address, and appropriate payment information.
        </p>

        <h4 className="mt-1">Information You Provide When Requesting Assistance</h4>
        <p className="my-p75">
          When you request assistance through or regarding the Service, you may also provide us with personally
          identifiable information. For example, if you email us regarding an order, you will provide us with your email
          address; if you email us regarding a shipping issue, you will provide us with your physical address.
        </p>

        <h3 className="mt-2">Other Information We Collect</h3>
        <h4 className="mt-1">Log Files & Analytics</h4>
        <p className="my-p75">
          Similar to many websites and internet services, we gather certain information automatically and store it in
          web server log files. This information includes your web request, Internet Protocol (“IP”) address
          information, unique device identifier (e.g., UDID, MAC Address, etc.), browser type, referring / exit pages
          and URLs, number of clicks, domain names, landing pages, pages viewed, and other such information. We may
          analyze such data using our own analytics, Google Analytics, or other similar third-party services. To learn
          more about how Google Analytics collects and uses your data when you use this site, and how you can control
          the information sent to Google Analytics, please review{' '}
          <a href="https://google.com/policies/privacy/partners">https://google.com/policies/privacy/partners</a>. We
          use these log files and analytics to improve and administer our site, to analyze trends, and to gather
          information about our user base.
        </p>

        <h4 className="mt-1p5">Pixels, aka Web Beacons</h4>
        <p className="my-p75">
          We may employ pixels, aka web beacons, which are small blocks of code that do things like allow us or another
          server to measure viewing of a webpage and to track the online usage patterns of our Members and Visitors. In
          addition, we may also use pixels or web beacons in HTML-based emails we send, to help us track which emails
          are opened by recipients. This information is used to enable more accurate reporting, improve the
          effectiveness of our marketing, and make the Service better for our Members and Visitors. We may ask
          advertisers or other partners to serve ads or services to computers, mobile phones, or other devices, which
          may use a cookie, pixel, or other similar technology placed by us or the third party.
        </p>

        <h4 className="mt-1p5">Cookies</h4>
        <p className="mt-p75">
          A cookie is a small text file that is stored on the computer of a Members or Visitors for record-keeping
          purposes. It can also help us understand how you click to our site, what pages you view during your visit, and
          your account and preference information as a Member of the Service. Cookies also allow us to track and
          understand the interests of our members, to help us enhance the overall experience on our site.
        </p>
        <p className="my-p5">
          If you prefer not to receive cookies, you can set your web browser software to disable them. To do so, please
          refer to the help section of your specific web browser for more information on how to manage or disable
          cookies.
        </p>

        <h4 className="mt-1p5">Do Not Track</h4>
        <p className="my-p75">
          At this time, the Service does not respond to “do not track” signals sent from browsers.
        </p>

        <h3 className="mt-2">Information We Do Not Collect From Any Users</h3>
        <h4 className="mt-1">Children</h4>
        <p className="my-p75">
          As explained further in our terms of service (available at{' '}
          <Link to={routes.app.termsOfService}>https://inkpop.com/terms-of-service</Link>), you must be 18 years of age
          or older – or, if you are under the age of majority in your jurisdiction, have the consent of your parent or
          legal guardian – to use the Service. We do not knowingly collect personally identifiable information from
          children. In accordance with Federal Children’s Online Privacy Protection Act of 1998 (“COPPA”), we will never
          knowingly solicit, nor will we accept, personally identifiable information from those known to be under
          thirteen (13) years of age. If you believe that we have information from or about anyone under thirteen (13)
          years of age, please notify our Privacy Officer by email at privacy@inkpop.com.
        </p>

        <h4 className="mt-1p5">Payment Information</h4>
        <p className="my-p75">
          The Service makes it quick and convenient for you to make purchases and receive funds using third party
          electronic payment processing services. When you make such a payment or receive funds, we will not receive any
          additional personally identifiable information as a result of these transactions. Third-party payment
          providers operate independently of our business; we encourage you to fully review the terms of service and
          privacy policies of any such service you may elect to use.
        </p>

        {/* How We Use Information */}
        <h2 className="mb-p75 mt-3">How we use Information</h2>
        <h4 className="mt-1">To Provide You With The Service</h4>
        <p className="my-p75">
          We use your information for the purposes of providing you with the Service and any Products you may choose to
          order using the Service.
        </p>

        <h4 className="mt-1p5">In Our Communications With You</h4>
        <p className="my-p75">
          We use email and/or prominent notifications on our website as our primary means of informing our users of
          important Service and promotional announcements.
        </p>
        <h5 className="mt-1">Service-Related Announcements</h5>
        <p className="my-p5">
          When you register to become a Member of the Service, we use the email address provided during account creation
          (or any other email address you may specify within your Member account) to deliver important Service-related
          announcements to you. Examples of Service-related announcements include email verification during
          registration, requests for password reset, important security or server maintenance updates, upgrades, and new
          releases or policy changes.
        </p>
        <h5 className="mt-1">Promotional Announcements</h5>
        <p className="my-p5">
          In order to improve the experience for our Members and Visitors, we may send regular updates from time to
          time. These communications are enhancements to our Service and are entirely optional. We make it easy for you
          to unsubscribe from these communications via a link in the footer of each such email you receive.
        </p>

        <h4 className="mt-1p5">With Our Sister Services</h4>
        <p className="my-p75">
          We may use your information in connection with other websites, services, and entities that are under our
          common ownership or common corporate control. We may combine information you provide while using the Service
          with information you provide to other websites, services, and entities that are under our common ownership or
          common corporate control.
        </p>

        <h4 className="mt-1p5">On Our Website</h4>
        <p className="my-p75">
          We may use the information we collect to calculate and publish anonymous, non-personal, aggregate statistics
          that we believe our Members and Visitors are interested in viewing. For example, we may track and publish the
          total number of Buyers who have ordered a specific shirt color or style. We publish this data in aggregate
          form only, and do not publish any personally identifiable information in connection with this use.
        </p>

        <h4 className="mt-1p5">For Internal Reporting and Analysis</h4>
        <p className="my-p75">
          As noted above, we use the information gathered from log files, cookies, and the like to improve our site,
          analyze trends, administer the site, track the movements of users around the site, and gather aggregate
          information about our customers as a whole.
        </p>

        {/* How We Share and Disclose Information */}
        <h2 className="mb-p75 mt-3">How We Share and Disclose Information</h2>

        <h4 className="mt-1">With Third Parties</h4>
        <h5 className="mt-1">To Provide You With The Service</h5>
        <p className="my-p75">
          We share your information, including your personally identifiable information, for the purpose of providing
          you with the Service and with any Product you may order through the Service. For example, if you order a
          physical Product though the Service, we will provide our shipping partners with your name and physical
          address.
        </p>
        <h5 className="mt-1">To Help Connect Buyers and Sellers</h5>
        <p className="my-p5">
          If you place an order from a Seller who is or becomes a member of our VIP Seller Program, we will share your
          email address with the Seller. This allows Buyers and Sellers to directly connect, and helps Sellers gain
          valuable feedback from our community and promote their upcoming projects and Campaigns.
        </p>
        <h5 className="mt-1">Sharing, Renting, & Selling Information We Collect</h5>
        <p className="my-p5">
          Except as provided herein, we will neither rent nor sell your personally identifiable information to any other
          third party not under our common ownership or common corporate control. Occasionally we share aggregate
          information about our Members and Visitors with partners or advertisers, and we may publish these aggregate
          usage statistics on our website for the interest of our users.
        </p>
        <h5 className="mt-1">Linked Services</h5>
        <p className="my-p5">
          In some cases, we allow you to access or link to other web services through our Service. For example, we may
          allow you to share Designs using Facebook and Twitter, among other services, which requires that you open
          accounts on those services themselves. We also allow you to pay for Products purchased through the Service
          using our selected third party payment-processing provider. In such cases, those services may choose to
          collect your personal information as they deem appropriate. As noted above, we are neither responsible for nor
          in control of how third parties collect, use, or disclose information obtained through these linked services.
          We encourage you to be aware when using these third-party services and to review their policies before
          submitting any personal information to them.
        </p>
        <h5 className="mt-1">Third-Party Service Providers</h5>
        <p className="my-p5">
          We use other companies, agents, and/or contractors to perform services on our behalf or to assist us with
          providing the Service to you. For example, we may engage such service providers to provide marketing,
          communications, infrastructure and IT services, personalize and optimize our service, process credit card
          transactions, provide customer service, collect debts, and analyze and enhance data. In the course of
          providing such services to us, these other companies may have access to your information. We do not authorize
          these companies to use or disclose your personal information, except in connection with providing the services
          we request from them.
        </p>
        <p className="my-p5">
          These vendors may anonymize and aggregate data collected from you and use that data for purposes of improving
          their product or providing services to us or other clients.
        </p>

        <h4 className="mt-1p5">As Necessary In Certain Legal Circumstances</h4>
        <h5 className="mt-1">Legal Requirements</h5>
        <p className="my-p5">
          We reserve the right to disclose your information, including your personally identifiable information, when we
          have a good faith belief that access, use, preservation, or disclosure of such information is necessary to (a)
          comply with any law, regulation, legal process, or enforceable governmental request; (b) enforce our terms of
          use, including investigation of potential violations of our terms of use; (c) detect, prevent, or otherwise
          address fraud or security or technical issues; or (d) protect against harm to our rights, property, or safety,
          or those of our employees, users, or the public, as required or permitted by law.
        </p>
        <p className="my-p5">
          Pursuant to California Civil Code Section 1798.83, known as the “Shine the Light” law, and as provided herein,
          we will disclose the names, email addresses, physical addresses, and business description of VIP Seller
          Program members when required by law, including in response to “Shine the Light” requests we receive.
        </p>
        <h5 className="mt-1">Acquisition</h5>
        <p className="my-p5">
          If we become involved in a merger, acquisition, or any form of sale of some or all of our assets, including
          bankruptcy, our assets, including Member account information and personally identifiable information, may be
          transferred to a third party. At that time, your Member account information and personally identifiable
          information may become subject to that third-party’s privacy policy.
        </p>
        <h5 className="mt-1">CA Shine The Light / Your California Privacy Rights</h5>
        <p className="my-p5">
          California Civil Code Section 1798.83, known as the “Shine the Light” law, permits our customers who are
          California residents to request and obtain from us a list of what personal information (if any) we may have
          disclosed to third parties for direct marketing purposes in the preceding calendar year, including the names
          and addresses of those third parties. Requests may be made only once a year and are free of charge. If you
          would like to make a request pursuant to California Civil Code Section 1798.83, you must do so in writing via
          email to privacy@inkpop.com; please include the phrase “Request for California Privacy Information” in the
          subject line and in the body of your message.
        </p>

        {/* How We Keep Information Secure */}
        <h2 className="mb-p75 mt-3">How We Share and Disclose Information</h2>

        <h4 className="mt-1">Security Of Account Information</h4>
        <p className="my-p75">
          The accounts of registered Members are password protected. Please do not share your password with anyone;
          remember to change your password from time to time, to keep your account secure.
        </p>
        <h4 className="mt-1p5">Network Security</h4>
        <p className="my-p75">
          Both we and our partners employ a variety of commercially standard encryption and security technologies and
          procedures to protect information from unauthorized access. Please be aware that no method of electronic
          storage or data transmission over the internet is 100% secure; while we strive to use commercially acceptable
          means to protect your personal information, we cannot guarantee its absolute security.
        </p>
        <h4 className="mt-1p5">Management Procedures</h4>
        <p className="my-p75">
          Both we and our partners maintain standard physical and electronic procedural safeguards that limit access to
          your personally identifiable information and other data to employees (or people working on our behalf and
          under confidentiality agreements) who, through the course of standard business activities or provision of the
          Service, need to access your personally identifiable information or other data.
        </p>

        {/* Your Personal Choices */}
        <h2 className="mb-p75 mt-3">Your Personal Choices</h2>

        <h4 className="mt-1">Access To Personal Information</h4>
        <p className="my-p75">
          We make it easy for Members to view, edit, and/or delete the information they have submitted to the Service;
          that information is available for editing in the “Settings” section of your Member account.
        </p>
        <h4 className="mt-1p5">Receiving Communications</h4>
        <p className="my-p75">
          If you subscribe to an optional promotional communication through the Service, you will be able to unsubscribe
          via a link in the footer of each such email you receive.
        </p>
        <h4 className="mt-1p5">Editing / Deleting Your Account</h4>
        <p className="my-p75">
          To remove your Member account from the Service entirely, please contact us by email at privacy@inkpop.com.
        </p>
        <h4 className="mt-1p5">Opting Out of Advertising</h4>
        <p className="my-p75">
          If you wish to opt out from receiving interest-based advertising, you can do so by clicking here if you are in
          the US or clicking here if you are in the EU. Please note, because our opt-out is cookie-based, your browser
          must be set to accept third party cookies.
        </p>

        {/* Updates to Our Privacy Policy */}
        <h2 className="mb-p75 mt-3">Updates to Our Privacy Policy</h2>
        <p className="mt-p75">
          From time to time, we will review and may make changes to our privacy policy. Please check our Privacy Policy
          periodically for changes. We will provide additional notice of significant updates. We will post the date our
          Policy was last updated at the top of the Privacy Policy. Your continued use of the Service after the posting
          of the modified Privacy Policy constitutes your agreement to abide and be bound by it. The most recent version
          of our privacy policy is always available from a link on our homepage at the url{' '}
          <Link to={routes.app.privacyPolicy}>https://inkpop.com/privacy-policies</Link>.
        </p>
        <p className="my-p5">
          If you have any questions about this privacy policy, the practices of the Service, or your dealings with the
          Service, please contact us at this email address: privacy@inkpop.com
        </p>

        <div className="my-4 lg:my-7">
          <CantFindProduct />
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Inkpop - Privacy Policy</title>
        </Helmet>
        <React.Fragment>
          <div className="w-full bgc-gray-50">
            <div className="w-full flex flex-wrap lg:w-container lg:mx-auto lg:py-0">
              <div className="d-ib w-1/2 ta-left my-1p5 px-1 flex items-center fs-xs">
                <Link to={'/'} className={''}>
                  <span className="d-ib color-blue">Home</span>
                </Link>
                <div className="d-ib">
                  <i className={'mdi mdi-chevron-right p-0 color-gray-500 fw-bold'} />
                </div>
                <div className="d-ib">
                  <span className="d-ib color-blue color-gray-500 fw-bold">Help</span>
                </div>
              </div>
              <div className="w-full ta-left px-1">
                <h3 className="color-navy fs-3xl fw-extra-bold pb-1p5 mt-1p5">Privacy Policy</h3>
                <h4 className="color-gray-500 pb-3">Last updated July 15th 2019</h4>
              </div>
            </div>
          </div>
          <div className="w-full bgc-white">
            <div className="w-full lg:w-container lg:mx-auto lg:py-0">
              <div className="w-full lg:w-7/10 lg:mx-auto">{this.renderContent()}</div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
